.front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    margin-top: 0vh;
    width: 100%;
    height: 80vh;
    background-color: #1c3d5a;
}

.front-text {
    width: 50%;
    padding: 3%;
    background-color: white;
}

.front-text h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #2b3a4b;
  margin-bottom: 0;
  line-height: 1.2;
}

.front-text p {
  font-size: 1.05rem;
  color: #444f5a;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
}



.element-containter {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; 
    width: 100vw;
    overflow: hidden;
}

.element-containter h1 {
  color: #2b3a4b;
}

.description-photo-containter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    align-items: center;
    justify-content: space-around; 
}

.description-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly; 
    width: 500px;
}

.description-containter h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #2b3a4b;
    margin-bottom: 0;
    line-height: 1.2;
}

.description-containter p {
    font-size: 1.05rem;
    color: #444f5a;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 2rem;
}

.description-containter strong {
    font-weight: 700;
    color: #2b3a4b;
}

.description-containter button {
    background-color: white;
    color: #3da9fc;
    border: 2px solid #3da9fc;
    border-radius: 1px;
    padding: 10px 24px;
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(61, 169, 252, 0.2);
    transition: all 0.2s ease;
}

.description-containter button:hover {
    background-color: #3291e6;
    border: 2px solid #3291e6;
    color: white;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.photo-container {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 600px;
    border: 2px solid black;
}

.icon-text-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around; 
}




.icon-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; 
    height: 400px;
    width: 300px;
    border: 2px solid black;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.icon-text:hover {
    border: 2px solid #00F7EA;
    background-color: #00F7EA; /* Change link color on hover */
    
}



.article-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 30px;
    
}

.article {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 2px dashed #3da9fc;
    justify-content: space-evenly;
}

.article:hover {
    color: white;
    border: 2px solid #3291e6;
    background-color: #3291e6; /* Change link color on hover */
}

.article:hover a {
    color: white;
}

.article h2 {
    margin-bottom: 0;
}

.article h2 a {
    color: #1c3d5a;
    text-decoration: none;
    transition: text-decoration 0.3s ease;
}
  
.article h2 a:hover {
    text-decoration: underline;
}

.article-meta {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap; /* Optional if you want it to break on small screens */
}




.team-container {
    display: flex;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
  
  .team-card {
    width: 220px;
    text-align: center;
    padding: 16px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0,0,0,0.06);
  }
  
  .team-photo {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  
  .role {
    font-weight: 600;
    color: #555;
    margin-bottom: 8px;
  }
  
  .description {
    list-style: none;
    padding: 0;
    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .social-icons a {
    margin: 0 6px;
    color: #3da9fc;
    font-size: 1.2rem;
    transition: color 0.2s;
  }
  
  .social-icons a:hover {
    color: #1d76d0;
  }



  .newsletter-wrapper {
    border: 1px dashed #3da9fc;
    padding: 80px;
    max-width: 100vw;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .newsletter-wrapper h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 5rem;
    font-size: 1.8rem;
    color: #2b3a4b;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* background-color: grey; */
  }
  
  .newsletter-form p {
    font-size: 0.95rem;
    color: #3a3a3a;
  }
  
  .newsletter-form label {
    font-weight: 600;
    font-size: 0.95rem;
    color: #2b3a4b;
  }
  
  .newsletter-form input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: #f7f9fc;
  }
  
  .newsletter-form button {
    margin-top: 1rem;
    align-self: center;
    background-color: #3da9fc;
    color: white;
    border: none;
    padding: 10px 24px;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .newsletter-form button:hover {
    background-color: #3291e6;
  }