
/* Navbar.css */

.nav-bar-wrapper {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  height: 12vh;
  margin-bottom: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  height: 85%;
  margin-left: 4rem;
  margin-right: 30rem;
}

.logo img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #1c3d5a;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: color 0.2s ease, text-decoration 0.2s ease;
}

.nav-links li a:hover {
  color: #3da9fc;
  text-decoration: underline;
}